<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="800px"
    custom-class="dub-dialog"
    close-on-click-modal
    destroy-on-close
    :before-close="handleClose"
  >
    <div class="dub-dialog-body">
      <video ref="videoRef" v-if="dubDetail.videoUrl" :src="dubDetail.videoUrl" controls="controls" muted width="744" @play="videoPlay" @pause="videoPause" @seeking="videoSeeking" @seeked="videoSeeked" @timeupdate="videoTimeUpdate" @ended="videoEnded">
        您的浏览器不支持 video 标签。
      </video>
      <div class="user-info">
        <img src="../../../../assets/student_default.png" alt="" width="48" height="48">
        <span class="user-name">{{ dubDetail.userName }}</span>
      </div>
      <div class="answer-info">
        <div :class="['answer-score', common.getScoreClassName(dubDetail.score)]">{{ formatNum(dubDetail.score || 0, 1) }}</div>
        <div class="answer-accuracy answer-item">
          <span class="answer-item-label">准确度</span>
          <el-progress
            class="answer-item-progress"
            :percentage="dubDetail.accuracy"
            :color="customColors"
            :show-text="false"
            define-back-color="#E5E5E5"
          />
        </div>
        <div class="answer-overall answer-item">
          <span class="answer-item-label">流利度</span>
          <el-progress
            class="answer-item-progress"
            :percentage="dubDetail.overall"
            :color="customColors"
            :show-text="false"
            define-back-color="#E5E5E5"
          />
        </div>
        <div class="answer-integrity answer-item">
          <span class="answer-item-label">完整度</span>
          <el-progress
            class="answer-item-progress"
            :percentage="dubDetail.integrity"
            :color="customColors"
            :show-text="false"
            define-back-color="#E5E5E5"
          />
        </div>
      </div>
      <div v-if="dubDetail.info && dubDetail.info.sentenceLibs" class="sentence-list">
        <div v-for="sentence in dubDetail.info.sentenceLibs" :key="sentence.sentenceId" class="sentence-item">
          <div class="sentence-text">
            <div class="sentence-value" v-html="sentence.text" />
            <div class="sentence-translated">{{ sentence.translatedText }}</div>
          </div>
          <img class="sentence-audio" v-if="selectSentence.sentenceId === sentence.sentenceId && videoAudioStatus" src="../../../../assets/sound-green.gif" alt="" width="40" height="40" @click="playAudio(sentence)">
          <img class="sentence-audio" v-else src="../../../../assets/sound-green.png" alt="" width="40" height="40" @click="playAudio(sentence)">
          <div class="sentence-record">
            <img class="sentence-record-user" src="../../../../assets/student_default.png" alt="" width="40" height="40">
            <div v-if="sentence.audioURL" class="sentence-record-audio" @click="playRecord(sentence)">
              <img v-if="selectSentence.sentenceId === sentence.sentenceId && recordAudioStatus" src="../../../../assets/sound_wave.gif" alt="" width="20" height="20">
              <img v-else src="../../../../assets/sound_wave.png" alt="" width="20" height="20">
            </div>
          </div>
          <div :class="['sentence-item-score', common.getScoreClassName(sentence.overall)]">
            {{ sentence.overall }}
          </div>
        </div>
      </div>
      <!-- 分句音频播放器 -->
      <audio
        ref="dubVideoAudioRef"
        class="dub-audio"
        :src="dubDetail.videoAudioUrl || dubDetail.videoUrl"
        @play="videoAudioStatus = true"
        @pause="videoAudioStatus = false"
        @ended="videoAudioStatus = false"
        @timeupdate="ontimeupdate"
      />
      <!-- 录音音频播放器 -->
      <audio
        ref="recordAudioRef"
        class="dub-audio"
        src="#"
        @play="recordAudioStatus = true"
        @pause="recordAudioStatus = false"
        @ended="recordAudioStatus = false"
      />
      <audio ref="completedRecordAudioRef" :src="completedRecordUrl" />
    </div>
  </el-dialog>
</template>

<script>
import Decimal from 'decimal.js'
// 配音作答详情
export default {
  name: 'DubDialog',
  props: {
    dubDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      customColors: [
        {color: '#FF3C30', percentage: 60},
        {color: '#FFA516', percentage: 85},
        {color: '#1DC060', percentage: 100}
      ],
      videoAudio: null,
      videoAudioStatus: false,
      recordAudio: null,
      recordAudioStatus: false,
      video: null,
      videoStatus: false,
      selectSentence: {},
      completedRecordAudio: null
    }
  },
  computed: {
    completedRecordUrl() {
      if (this.dubDetail.info && this.dubDetail.info.audioURL) {
        return this.dubDetail.info.audioURL
      }
      return ''
    }
  },
  methods: {
    changeVisible(bol) {
      if (bol) {
        this.$nextTick(() => {
          console.log(this.dubDetail)
          this.video = this.$refs.videoRef
          this.videoAudio = this.$refs.dubVideoAudioRef
          this.recordAudio = this.$refs.recordAudioRef
          this.completedRecordAudio = this.$refs.completedRecordAudioRef
        })
      }
      this.dialogVisible = bol
    },
    handleClose(done) {
      console.log('关闭前')
      done()
    },
    formatNum(num, precision) {
      return new Decimal(num).toFixed(precision, Decimal.ROUND_HALF_UP)
    },
    videoPlay() {
      this.videoStatus = true
      this.video.muted = true
      this.completedRecordAudio.currentTime = this.video.currentTime
      this.completedRecordAudio.play()
      if (this.recordAudio && !this.recordAudioStatus) this.recordAudio.pause()
      if (this.videoAudio && !this.videoAudioStatus) this.videoAudio.pause()
    },
    videoPause() {
      this.completedRecordAudio.pause()
      this.videoStatus = false
    },
    videoSeeking() {
      if (!this.video.paused) {
        this.video.pause()
      }
      if(!this.completedRecordAudio.paused) {
        this.completedRecordAudio.paused()
      }
    },
    videoSeeked() {
      if (this.video.paused) return
      this.completedRecordAudio.currentTime = this.video.currentTime
      this.completedRecordAudio.play()
      this.video.play()
    },
    videoTimeUpdate() {
      this.video.muted = true
    },
    videoEnded() {
      this.completedRecordAudio.pause()
      this.videoStatus = false
    },
    // 播放句子原音频
    playAudio(sentence){
      if (this.recordAudio && !this.videoAudioStatus) this.recordAudio.pause()
      if (this.video && this.videoStatus) this.video.pause()
      if (this.selectSentence.sentenceId === sentence.sentenceId) {
        if (this.videoAudio) {
          if (this.videoAudioStatus) {
            this.videoAudio.pause()
          } else {
            this.videoAudio.play()
          }
        }
      } else {
        this.selectSentence = sentence
        if (this.videoAudio) {
          if (!this.videoAudioStatus) this.videoAudio.pause()
          this.videoAudio.currentTime = sentence.beginTime
          this.videoAudio.play()
        }
      }
    },
    // 播放录音
    playRecord(sentence) {
      if (this.videoAudio && this.videoAudioStatus) this.videoAudio.pause()
      if (this.video && this.videoStatus) this.video.pause()
      if (this.selectSentence.sentenceId !== sentence.sentenceId) {
        this.selectSentence = sentence
      }
      if (this.recordAudio) {
        if (this.recordAudio.src !== sentence.audioURL) {
          this.recordAudio.src = sentence.audioURL
          this.recordAudio.play()
        } else {
          if (this.recordAudioStatus) {
            this.recordAudio.pause()
          } else {
            this.recordAudio.play()
          }
        }
      }
    },
    // 监听原音频进度
    ontimeupdate() {
      if (this.videoAudio.currentTime >= this.selectSentence.endTime) {
        this.videoAudio.pause()
        this.videoAudio.currentTime = this.selectSentence.beginTime
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dub-dialog-body{
  padding: 20px 28px;
  .user-info{
    margin-top: 20px;
    display: flex;
    align-items: center;
    img{
      width: 48px;
      height: 48px;
      border-radius: 24px;
    }
    .user-name{
      margin-left: 12px;
      color: #333333;
      font-size: 17px;
      line-height: 24px;
    }
  }
  .answer-info{
    margin-top: 10px;
    display: flex;
    align-items: center;
    .answer-score{
      width: 150px;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: url("../../../../assets/score_bg.png") no-repeat center center;
      background-size: 150px 90px;
      &.success {
        color: #11BF69;
      }
      &.waning {
        color: #FFA800;
      }
      &.error {
        color: #F13A3A;
      }
    }
    .answer-item{
      margin-left: 20px;
      display: flex;
      align-items: center;
      width: 178px;
      height: 34px;
      background: #F6F6F6;
      border-radius: 17px;
      padding: 0 14px;
      .answer-item-label{
        width: 39px;
        color: #333333;
        font-size: 13px;
        line-height: 18px;
        margin-right: 10px;
      }
      .el-progress{
        width: 100px;
      }
      //.answer-item-progress{
      //  width: 100px;
      //}
    }

  }
  .sentence-list{
    .sentence-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      &+.sentence-item{
        border-top: 1px solid #F1F1F1;
      }
      .sentence-text{
        flex: 1;
        padding: 20px 0;
        .sentence-value{
          font-size: 24px;
          line-height: 33px;
          font-weight: bold;
        }
        .sentence-translated{
          margin-top: 14px;
          color: #999999;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .sentence-audio{
        border-radius: 20px;
      }
      .sentence-record{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-left: 20px;
        position: relative;
        .sentence-record-user{
          border-radius: 20px;
        }
        .sentence-record-audio{
          width: 40px;
          height: 40px;
          border-radius: 20px;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .sentence-item-score{
        width: 76px;
        height: 68px;
        margin-left: 2px;
        text-align: center;
        line-height: 68px;
        background-size: 76px 68px;
        &.success {
          color: #11BF69;
          background: url("../../../../assets/score_bg_green.png") no-repeat center center;
          background-size: 76px 68px;
        }
        &.waning {
          color: #FFA800;
          background: url("../../../../assets/score_bg_yellow.png") no-repeat center center;
          background-size: 76px 68px;
        }
        &.error {
          color: #F13A3A;
          background: url("../../../../assets/score_bg_red.png") no-repeat center center;
          background-size: 76px 68px;
        }
      }
    }
  }
  .dub-audio{
    //width: 0;
    //height: 0;
  }
}
</style>
<style lang="scss">
.dub-dialog{
  //background: transparent;
  border-radius: 16px;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>
